import "./styles.scss"

import React from "react"

import { useLocale } from "context/locale"

const Content = ({ pageData }) => {
  const { t } = useLocale()

  return (
    <section className="download-content">
      <div className="container-fluid">
        <div className="row">
          {pageData?.acfDownload?.knowledge?.length > 0 && (
            <div className="col-xl-6">
              <h3>{t("Pigułka wiedzy")}</h3>
              <div className="row">
                {pageData?.acfDownload?.knowledge?.map((item, index) => (
                  <div className="col-xl-6 col-md-3 col-6" key={index}>
                    <a
                      href={
                        item?.fileUrl ? item?.fileUrl : item?.file?.mediaItemUrl
                      }
                      className="download-content__item"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={item?.icon?.localFile?.publicURL} alt="" />
                      <strong>{item?.header}</strong>
                    </a>
                  </div>
                ))}
              </div>
            </div>
          )}

          {pageData?.acfDownload?.technicalSpecifications?.length > 0 && (
            <div className="col-xl-6">
              <h3>{t("Specyfikacje techniczne")}</h3>
              <div className="row">
                {pageData?.acfDownload?.technicalSpecifications?.map(
                  (item, index) => (
                    <div className="col-xl-6 col-md-3 col-6" key={index}>
                      <a
                        href={
                          item?.fileUrl
                            ? item?.fileUrl
                            : item?.file?.mediaItemUrl
                        }
                        className="download-content__item"
                        target="_blank"
                        rel="noreferrer"
                      >
                        <img src={item?.icon?.localFile?.publicURL} alt="" />
                        <strong>{item?.header}</strong>
                      </a>
                    </div>
                  )
                )}
              </div>
            </div>
          )}

          <div className="download-content__divider mobile" />

          {pageData?.acfDownload?.manuals?.length > 0 && (
            <div className="col-xl-6">
              <h3>{t("Instrukcje montażu")}</h3>
              <div className="row">
                {pageData?.acfDownload?.manuals?.map((item, index) => (
                  <div className="col-xl-6 col-md-3 col-6" key={index}>
                    <a
                      href={
                        item?.fileUrl ? item?.fileUrl : item?.file?.mediaItemUrl
                      }
                      className="download-content__item"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img src={item?.icon?.localFile?.publicURL} alt="" />
                      <strong>{item?.header}</strong>
                    </a>
                  </div>
                ))}
              </div>
            </div>
          )}
        </div>

        <div className="download-content__divider" />

        <h3>{t("Wizualizacje wnętrz z tapetami")}</h3>
        <div className="row">
          {pageData?.acfDownload?.visualisations?.map((item, index) => (
            <div className="col-xl-2 col-md-3 col-6" key={index}>
              <a
                href={item?.fileUrl ? item?.fileUrl : item?.file?.mediaItemUrl}
                className="download-content__item"
                target="_blank"
                rel="noreferrer"
              >
                <img src={item?.icon?.localFile?.publicURL} alt="" />
                <strong>{item?.header}</strong>
              </a>
            </div>
          ))}
        </div>

        <div className="download-content__divider" />

        <h3>{t("Wzory tapet")}</h3>
        <div className="row">
          {pageData?.acfDownload?.wallpapersTextures?.map((item, index) => (
            <div className="col-xl-2 col-md-3 col-6" key={index}>
              <a
                href={item?.fileUrl ? item?.fileUrl : item?.file?.mediaItemUrl}
                className="download-content__item"
                target="_blank"
                rel="noreferrer"
              >
                <img src={item?.icon?.localFile?.publicURL} alt="" />
                <strong>{item?.header}</strong>
              </a>
            </div>
          ))}
        </div>

        <div className="download-content__divider" />

        <h3>{t("Materiał - podkłady")}</h3>
        <div className="row">
          {pageData?.acfDownload?.materialsTextures?.map((item, index) => (
            <div className="col-xl-2 col-md-3 col-6" key={index}>
              <a
                href={item?.fileUrl ? item?.fileUrl : item?.file?.mediaItemUrl}
                className="download-content__item"
                target="_blank"
                rel="noreferrer"
              >
                <img src={item?.icon?.localFile?.publicURL} alt="" />
                <strong>{item?.header}</strong>
              </a>
            </div>
          ))}
        </div>
      </div>
    </section>
  )
}

export default Content
